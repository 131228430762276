import { useState } from "react";
import { OAuthProvider, signOut, signInWithRedirect, getRedirectResult, setPersistence, browserSessionPersistence, inMemoryPersistence, signInWithPopup} from "firebase/auth";
import { auth, loginObserver } from '../../firebase';
import Button from '@mui/material/Button';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import {useNavigate} from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/Login';

const Login = (props) => {
        const [user, setUser] = useState();
        const navigate = useNavigate();
      // TODO make two login functions

      // Check if user is loged in, or out
      loginObserver(auth, (userData) => {
            setUser(userData)
            if(user) {

                if(window.localStorage.getItem('registrationType') === "Patient") {
                    return navigate("/patient");
                }
        
                if(window.localStorage.getItem('registrationType') === "Doctor") {
                    return navigate("/doctor");
                }
            }
        });

      const login = (event, type) => {  
        window.localStorage.setItem('registrationType', type);
        const provider = new OAuthProvider('oidc.tester');
        provider.addScope('openid'); 
    
        signInWithRedirect(auth, provider)
        .then(() => {

        })
        .then((result) => {
        
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("Noe gikk galt ::: ", errorCode, " ", errorMessage)
        });
        event.preventDefault();
      }

      if(!user) {
        if(props?.type === "Doctor") {
            return (
                <>
                    <Button
                        sx={{ color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                            backgroundColor: '#DD0A01'
                        } }}
                        size="large"
                        variant="contained" 
                        onClick={(event) => {
                            login(event, "Doctor")
                        }}>
                        <ExitToAppIcon sx={{mr: 1}} />
                        LOGG INN
                    </Button>
                </>
            )
        }

        if(props?.type === "Login") {
            return (
                <>
                    <Button
                        sx={{ display: 'flex', alignItems: 'center', color: 'white', borderRadius: '0', backgroundColor: 'transparent', "&:hover": {
                            backgroundColor: 'transparent'
                        } }}
                        size="large"
                        onClick={(event) => {
                            login(event, "Doctor")
                        }}>
                        LOGG INN
                        <LoginIcon sx={{ml: 1}} />
                    </Button>
                </>
            )
        }

        if(props?.type === "Patient") {
            return (
                <>
                <Button 
                    sx={{mr: 3, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                        backgroundColor: '#DD0A01'
                    } }}
                    size="large"
                    variant="contained" 
                    onClick={(event) => {
                        login(event, "Patient")
                    }}>
                    <QueryBuilderIcon sx={{mr: 1}} />
                    BESTILL TIME
                </Button>
                </>
            )
        }

        return (
            <>
                <Button 
                    sx={{mr: 3, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                        backgroundColor: '#DD0A01'
                    } }}
                    size="large"
                    variant="contained" 
                    onClick={(event) => {
                        login(event, "Patient")
                    }}>
                    <QueryBuilderIcon sx={{mr: 1}} />
                    BESTILL TIME
                </Button>
                <Button
                    sx={{ color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                        backgroundColor: '#DD0A01'
                    } }}
                    size="large"
                    variant="contained" 
                    onClick={(event) => {
                        login(event, "Doctor")
                    }}>
                    FOR HELSEPERSONELL
                </Button>
            </>
        )
    }

    return (
        <>
        </>
    )


}

export default Login