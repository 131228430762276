import * as React from "react";
import {useState, useEffect} from "react";
import { useOutletContext } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const SetPatientInformation = () => {

    const [user, setUser, userDb, setUserDb] = useOutletContext();

    const [mobile, setMobile] = useState();
    const [email, setEmail] = useState();
    const [ssn, setSsn] = useState();

    const setPatientInformation = async (event) => {
        const userRef = doc(db, "Users", user?.uid);
        updateDoc(userRef, {
        name: user?.displayName,
        id: user?.uid,
        email: email,
        mobile: mobile,
        ssn: ssn,
        firstTimeLogin: false
        }).then(async (result) => {
            const docRef = doc(db, "Users", user?.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Prøver å oppdattere dokument");
                setUserDb(docSnap.data())
            } else {
                console.log("Klarte ikke sette DB object etter creation")
            }
        }).catch((err) => {
            console.log("Error på DB update ::: ", err)
        });
        event.preventDefault();
    }
  
    if(user && userDb?.firstTimeLogin === true && userDb?.type === "Patient")  {
        return (
            <>
                <div className="index-patient">
                    <div className="container">
                        <h1>Skriv inn litt informasjon om deg selv</h1>
                        <form style={{ maxWidth: 700, margin: '0px auto', textAlign: 'center'}}>
                            <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { m: 1, width: '25ch',},
                                }}
                                className="doc-align"
                                noValidate
                                autoComplete="off"
                            >
                                <TextField 
                                    id="outlined-basic" 
                                    label="Telefonnummer" 
                                    variant="outlined"
                                    type="tel"
                                    onChange={(e) => {
                                        setMobile(e.target.value)
                                    }}
                                />
                                <TextField 
                                    id="outlined-basic" 
                                    label="E-post" 
                                    variant="outlined" 
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                                <TextField 
                                    id="outlined-basic" 
                                    label="Fødselsnummer (11 siffer)" 
                                    variant="outlined"
                                    onChange={(e) => {
                                        setSsn(e.target.value)
                                    }} 
                                />
                                <TextField 
                                    id="outlined-basic" 
                                    label="Eksempel" 
                                    variant="outlined"
                                />
                            </Box>
                            <div className="btn-holder">
                                <Button
                                    sx={{mt: 5, color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                                        backgroundColor: '#DD0A01'
                                    } }}
                                    size="large"
                                    variant="contained"
                                    onClick={(event) => {
                                        setPatientInformation(event);
                                    }}
                                >
                                GÅ VIDERE
                                <ArrowForwardIcon sx={{ml: 1}} />
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                {/*
                <button onClick={() => {
                        signOut(auth).then(() => {
                            // Sign-out successful.
                        }).catch((error) => {
                            // An error happened.
                        });
                    }}>
                    Logg ut    
                </button>
                */}
            </>
        )
    }

    return (
        <>
        </>
    )


}

export default SetPatientInformation