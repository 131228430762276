// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signInWithRedirect, getRedirectResult, OAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAb8ktX9tuTf4zK7qBdzgPb01HjNxZ5Vl0",
    authDomain: "wifi-87474.firebaseapp.com",
    projectId: "wifi-87474",
    storageBucket: "wifi-87474.appspot.com",
    messagingSenderId: "239239995662",
    appId: "1:239239995662:web:96222acedcc9cec63c775f",
    measurementId: "G-1TPJGK9M1J"
};

let app = initializeApp(firebaseConfig);
// Authentication observer
const auth = getAuth();
//connectAuthEmulator(auth, "http://localhost:9099");
const loginObserver = onAuthStateChanged;
const db = getFirestore(app);
const storage = getStorage(app);



export { db, loginObserver, auth, storage };