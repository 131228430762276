import * as React from 'react';
import {ReactComponent as Logo} from '../../assets/logo.svg';
import { Link } from 'react-router-dom';

export default function Footer() {

    // Return today's date and time
    const currentTime = new Date();
    // returns the year (four digits)
    const year = currentTime.getFullYear();

  return (
    <>
        <footer>
            <div className="container"> 
                <div className="inner-footer">
                    <Logo />
                    <p>© Wifiskop AS - {year} - All rights reserved</p>
                    <p>Contact us:  <a href="mailto:mail@wifiskop.com">mail@wifiskop.com</a></p> 
                    <p>
                        When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and
                        safer experience and for marketing purposes. <Link target="__blank" to="/personvern" href="/personvern">GDPR - Cookies</Link>
                    </p>
                </div>
            </div>
        </footer>
    </>
  );
}