import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Curve from '../assets/curve.png';

export default function Gdpr() {
  return (
    <>
        <div className="gdpr">
            <div className="container">
                <div className="inner">
                    <div className="gdpr-header">
                        <h1>GDPR Wifiskop</h1>
                        <p>Sist oppdatert: 24.01.2023</p>
                        <img className="curve" src={Curve} />
                    </div>
                    <div className="gdpr-body">
                        <div className="left">
                        <p>Som medlem av WifiSkop AS skal du være sikker på at dine personopplysninger blir behandlet på en forsvarlig måte. Denne personvernerklæringen forteller deg hvordan vi ivaretar dine personopplysninger og hva vi bruker dem til. Den inneholder også informasjon om hvordan vi sikrer personopplysningene, og hvem vi deler dem med, og dine rettigheter.</p>
                        <h3>Hva er en personopplysning?</h3>
                        <p>En personopplysning er enhver opplysning som kan knyttes til en enkeltperson, direkte eller indirekte, herunder navn, postadresse, e-postadresse, IP-adresse og mobilnummer. All bruk av personopplysninger (innsamling, registrering, lagring, sammenstilling og utlevering) regnes som behandling av personopplysninger.</p>

                        <h3>Hvem er behandlingsansvarlig?</h3>
                        <p>WifiSkop AS er behandlingsansvarlig for personopplysninger vi behandler i forbindelse med bruk av våre nettsider og tjenester.</p>

                        <h3>Hvilke personopplysninger behandler vi?</h3>
                        <p>Vi bruker disse personopplysningene i forbindelse med din registrering som bruker av www.wifiskop.com</p>
                        <p>Vi registrerer: navn, e-postadresse, telefonnummer, adresse og fødselsdato.</p>
                        <p>Det er frivillig å gi oss tilgang til dine personopplysninger, men vi vil trenge slik informasjon for å levere avtalte tjenester eller kontakte deg i forbindelse med din brukertilgang av WifiSkop.</p>

                        <h3>Hvordan får vi personopplysninger om deg?</h3>
                        <p>Vi får inn opplysninger på flere måter:</p>
                        <p>Vi mottar personopplysninger direkte fra deg når du registrerer deg som medlem eller kommer i kontakt med oss på andre måter. Disse opplysningene er nødvendige for at vi skal kunne levere våre tjenester, eller for å kunne følge opp dine henvendelser.</p>

                        <h3>Hva bruker vi personopplysningene til?</h3>
                        <p>Vi bruker personopplysninger for å yte og levere tjenester til deg. Blant annet brukes dine opplysninger i forbindelse med administrering av kundeforholdet.</p>
                        <p>Vi behandler personopplysninger for å oppfylle våre lovfestede plikter, f.eks. i tilknytning til regnskapsførsel og for å fremlegge opplysninger for kompetent myndighet når dette er pålagt oss ifølge norsk lovgivning.</p>

                        <h3>Hvorfor har vi lov til å bruke dine personopplysninger?</h3>
                        <p>Vår bruk av dine personopplysninger kan basere seg på ett eller flere av de følgende grunnlagene:</p>
                
                        <p>- Oppfyllelse av avtale med deg</p>
                        <p>- Oppfyllelse av en rettslig forpliktelse</p>
                        <p>- Formål knyttet til en berettiget interesse</p>
                        
                        <h3>Hvordan sikrer vi dine personopplysninger?</h3>
                        <p>WifiSkop AS har etablerte rutiner og tiltak for å sikre at uvedkommende ikke får tilgang til personopplysningene dine. All behandling av opplysningene skjer i tråd med gjeldende rett. Tiltakene inkluderer blant annet jevnlige risikovurderinger, tekniske systemer og fysiske prosedyrer for å ivareta informasjonssikkerhet og rutiner for å verifisere innsyns- og rettingsforespørsler.</p>
                        
                        <h3>Når sletter vi personopplysningene?</h3>
                        <p>WifiSkop AS oppbevarer ikke personopplysninger lenger enn det som er nødvendig for å levere våre tjenester eller for å oppfylle formålet med behandlingen på annen måte. Det er viktig at opplysningene vi har om deg er riktige og oppdaterte. Hvis du oppdager feil oppfordrer vi deg til å ta kontakt med oss. Du kan selvsagt også kontakte oss dersom du ønsker at opplysningene skal slettes.</p>
                        <h3>Hvem deler vi personopplysninger med?</h3>
                        <p>All utveksling av data gjøres i tråd med tydelige retningslinjer for behandling av persondata. Der det foreligger en lovfestet utleveringsplikt, vil offentlige myndigheter også motta informasjon.</p>
                        <h3>Dine rettigheter</h3>
                        <p>Personvernreglene gir deg en rekke rettigheter, herunder rett til innsyn i, retting av og sletting av de personopplysningene vi har lagret på deg.</p>
                        <p>Når det gjelder kravet om sletting, er det et unntak for de opplysninger som er nødvendig for at vi skal kunne levere en tjeneste som du fremdeles ønsker å ha tilgang til, eller det er lovpålagt å oppbevare opplysningene i en spesifikk periode.</p>
                        <p>Du har også rett til dataportabilitet. Dette innebærer at du blant annet får mulighet til å ta med deg dine personopplysninger i et maskinlesbart format.</p>
                        <p>Du har videre rett til å motsette deg behandling av personopplysninger og rett til å motsette deg personprofilering og automatiserte avgjørelser. Dette medfører at du kan kreve at dine personopplysninger ikke analyseres for å avdekke din adferd, preferanser eller behov.</p>
                        <p>Dersom du mener at vi ikke følger denne personvernerklæringen eller gjeldende lovgiving, setter vi pris på at du tar kontakt med oss umiddelbart.</p>
               
                        </div> {/* LEFT */}
                        <div className="right">
                            <div className="box">
                                <h3>Har du spørsmål angående vår GDPR?</h3>
                                <p>Ta kontakt på vår e-post som er lenket nedenfor</p>
                                <p><a href="mailto:mail@wifiskop.com">mail@wifiskop.com</a></p>
                                <h4>Adresse: Stranden 89, 0250 Oslo</h4>
                            </div>
                        </div> {/* RIGHT */}
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}