import Header from './Elements/header'
import Footer from './Elements/footer'

const Layout = ({ children }) => {
  return (
    <>
      <Header />
        {children}
      <Footer />
    </>
  )
}

export default Layout