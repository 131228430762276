import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {ReactComponent as FourOFourLogo} from '../assets/404.svg';

export default function FourOFour() {
  return (
    <>
        <div className="four-o-four">
            <div className="container">
                <h1>Siden du leter etter finnes ikke</h1>
                <div className="button-wrapper">
                    <Button
                        sx={{color: 'white', borderRadius: '25px', backgroundColor: '#DD0A01', "&:hover": {
                            backgroundColor: '#DD0A01'
                        } }}
                        size="large"
                        variant="contained"
                        onClick={() => window.location.href = "/"}
                    >
                        TIL FORSIDEN
                        <ArrowForwardIcon style={{marginLeft: '8px', display: 'inline-block'}} />
                    </Button>
                </div>
                <div className="logo-image">
                    <FourOFourLogo />
                </div>
            </div>
        </div>
    </>
  );
}